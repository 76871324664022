import {Config} from "../Config";
import {AuthResponse, refresh_token} from "./Auth";

const config: Config = new Config();

export type EventResponse = {
    succeeded: boolean,
    data: any | null,
    message: string,
}

export async function get_events(): Promise<EventResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, data: null, message: "Servers are down, please come back later"};
    }
    try {
        const response = await fetch(config.backendURL + config.endpoints.event_list, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        let data = await response.json();
        switch (response.status) {
            case 200:
                return {
                    succeeded: true,
                    data: data,
                    message: "Successfully loaded home page"
                };
            case 404:
                return {
                    succeeded: false,
                    data: null,
                    message: "Not found"
                };
            case 500:
                return {
                    succeeded: false,
                    data: null,
                    message: "Internal server error"
                };
            default:
                return {
                    succeeded: false,
                    data: null,
                    message: "Unknown error"
                };
        }
    } catch (e) {
        window.location.href = '/error';
        return {succeeded: false, data: null, message: "Something went wrong on our end"};
    }
}


export async function get_events_by_season(): Promise<EventResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, data: null, message: "Servers are down, please come back later"};
    }
    try {
        const response = await fetch(config.backendURL + config.endpoints.event_by_season + '2025/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        let data = await response.json();
        switch (response.status) {
            case 200:
                return {
                    succeeded: true,
                    data: data,
                    message: "Successfully loaded events by season"
                };
            case 404:
                return {
                    succeeded: false,
                    data: null,
                    message: "Not found"
                };
            case 500:
                return {
                    succeeded: false,
                    data: null,
                    message: "Internal server error"
                };
            default:
                return {
                    succeeded: false,
                    data: null,
                    message: "Unknown error"
                };
        }
    } catch (e) {
        window.location.href = '/error';
        return {succeeded: false, data: null, message: "Something went wrong on our end"};
    }
}


export async function delete_event(id: string): Promise<EventResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, data: null, message: "Servers are down, please come back later"};
    }
    try {
        const response = await fetch(config.backendURL + config.endpoints.event_delete + `${id}/` , {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + localStorage.getItem('access_token'),
            },

        });
        let data = await response.json();
        if(response.status < 400){
            return {
                succeeded: true,
                data: data,
                message: "Successfully deleted event"
            };
        }
        else if(response.status === 401){
            let refresh_validation: AuthResponse = await refresh_token(localStorage.getItem('refresh_token') ?? "");
            if (refresh_validation.succeeded) {
                return await delete_event(id);
            } else {
                return {succeeded: false, data: null, message: "Access token is invalid"};
            }
        }
        else{
            return {
                succeeded: false,
                data: null,
                message: "Unknown error"
            };
        }
    } catch (e) {
        window.location.href = '/error';
        return {succeeded: false, data: null, message: "Something went wrong on our end"};
    }

}

export async function upload_event_image(file: File, id: string): Promise<EventResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, data: null, message: "Servers are down, please come back later"};
    }
    try {
        const response = await fetch(config.backendURL + config.endpoints.event_upload_image + `${id}/` , {
            method: 'POST',
            headers: {
                'Content-Type': file.type,
                'Authorization': 'JWT ' + localStorage.getItem('access_token'),
                'Content-Disposition': 'attachment; filename=' + file.name,
            },
            body: file
        });
        let data = await response.json();
        if(response.status < 400){
            return {
                succeeded: true,
                data: data,
                message: "Successfully uploaded event image"
            };
        }
        else if(response.status === 401){
            let refresh_validation: AuthResponse = await refresh_token(localStorage.getItem('refresh_token') ?? "");
            if (refresh_validation.succeeded) {
                return await delete_event(id);
            } else {
                return {succeeded: false, data: null, message: "Access token is invalid"};
            }
        }
        else{
            return {
                succeeded: false,
                data: data,
                message: "Unknown error"
            };
        }
    } catch (e) {
        window.location.href = '/error';
        return {succeeded: false, data: null, message: "Something went wrong on our end"};
    }
}


export async function upload_left_sponsor_image(file: File, id: string): Promise<EventResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, data: null, message: "Servers are down, please come back later"};
    }
    try {
        const response = await fetch(config.backendURL + config.endpoints.event_left_sponsor_image_upload + `${id}/` , {
            method: 'POST',
            headers: {
                'Content-Type': file.type,
                'Authorization': 'JWT ' + localStorage.getItem('access_token'),
                'Content-Disposition': 'attachment; filename=' + file.name,
            },
            body: file
        });
        let data = await response.json();
        if(response.status < 400){
            return {
                succeeded: true,
                data: data,
                message: "Successfully uploaded event image"
            };
        }
        else if(response.status === 401){
            let refresh_validation: AuthResponse = await refresh_token(localStorage.getItem('refresh_token') ?? "");
            if (refresh_validation.succeeded) {
                return await delete_event(id);
            } else {
                return {succeeded: false, data: null, message: "Access token is invalid"};
            }
        }
        else{
            return {
                succeeded: false,
                data: data,
                message: "Unknown error"
            };
        }
    } catch (e) {
        window.location.href = '/error';
        return {succeeded: false, data: null, message: "Something went wrong on our end"};
    }
}


export async function upload_right_sponsor_image(file: File, id: string): Promise<EventResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, data: null, message: "Servers are down, please come back later"};
    }
    try {
        const response = await fetch(config.backendURL + config.endpoints.event_right_sponsor_image_upload + `${id}/` , {
            method: 'POST',
            headers: {
                'Content-Type': file.type,
                'Authorization': 'JWT ' + localStorage.getItem('access_token'),
                'Content-Disposition': 'attachment; filename=' + file.name,
            },
            body: file
        });
        let data = await response.json();
        if(response.status < 400){
            return {
                succeeded: true,
                data: data,
                message: "Successfully uploaded event image"
            };
        }
        else if(response.status === 401){
            let refresh_validation: AuthResponse = await refresh_token(localStorage.getItem('refresh_token') ?? "");
            if (refresh_validation.succeeded) {
                return await delete_event(id);
            } else {
                return {succeeded: false, data: null, message: "Access token is invalid"};
            }
        }
        else{
            return {
                succeeded: false,
                data: data,
                message: "Unknown error"
            };
        }
    } catch (e) {
        window.location.href = '/error';
        return {succeeded: false, data: null, message: "Something went wrong on our end"};
    }
}

export async function get_event(id: string): Promise<EventResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, data: null, message: "Servers are down, please come back later"};
    }
    try {
        const response = await fetch(config.backendURL + config.endpoints.event_detail + id + '/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        let data = await response.json();
        switch (response.status) {
            case 200:
                return {
                    succeeded: true,
                    data: data,
                    message: "Successfully loaded event detail info"
                };
            case 404:
                return {
                    succeeded: false,
                    data: null,
                    message: "Not found"
                };
            case 500:
                return {
                    succeeded: false,
                    data: null,
                    message: "Internal server error"
                };
            default:
                return {
                    succeeded: false,
                    data: null,
                    message: "Unknown error"
                };
        }
    } catch (e) {
        window.location.href = '/error';
        return {succeeded: false, data: null, message: "Something went wrong on our end"};
    }
}